import {
  HomePage,
  LoginPage,
  RiskAnalysisPage,
  OpdAnalysisPage,
  CampAnalysisPage,
  PatientDetailsPage,
  FileUploadProcessPage,
  ListSalesUserPage,
  AnalyticsPage,
  SalesAgentAnalyticsPage,
  SearchPage,
  OpAnalysisPageTab,
  IpAnalysisPageTab,
  WinbackAnalysisPageTab,
  AdminIpAnalysisPage,
  AdminOpAnalysisPage,
  RecommendationListPage,
  RecommendationDetailsPage,
  NonIPRecommendations,
  NonIPRecommendationsList,
  CampAnalysisPageWithFilters,
  OpComprehensive,
  HighIpPotential,
} from "./lazyImports";

export const routeConfig = [
  { path: "/", component: LoginPage, featureKey: null }, // Assuming login page doesn't require features
  { path: "/home", component: HomePage, featureKey: "home" },
  { path: "/ip", component: IpAnalysisPageTab, featureKey: "risk_analysis" },
  { path: "/op", component: OpAnalysisPageTab, featureKey: "opd_analysis" },
  {
    path: "/winback",
    component: WinbackAnalysisPageTab,
    featureKey: "winback_analysis",
  },
  { path: "/camp", component: CampAnalysisPage, featureKey: "camp_analysis" },
  {
    path: "/patients/camp",
    component: CampAnalysisPageWithFilters,
    featureKey: "camp_patients",
  },
  {
    path: "/patient-details/:patientId",
    component: PatientDetailsPage,
    featureKey: null,
  },
  {
    path: "/file-upload",
    component: FileUploadProcessPage,
    featureKey: "file_upload",
  },
  {
    path: "/list-sales-users",
    component: ListSalesUserPage,
    featureKey: "list_sales_user",
  },
  { path: "/analytics", component: AnalyticsPage, featureKey: "analytics" },
  {
    path: "/sales-agent-analytics",
    component: SalesAgentAnalyticsPage,
    featureKey: "sales_agent_analytics",
  },
  {
    path: "/patients/search",
    component: SearchPage,
    featureKey: "search_patients",
  },
  {
    path: "/patients/ip",
    component: AdminIpAnalysisPage,
    featureKey: "ip_patients",
  },
  {
    path: "/patients/op",
    component: AdminOpAnalysisPage,
    featureKey: "op_patients",
  },
  {
    path: "/recommendations",
    component: RecommendationListPage,
    featureKey: "recommendations",
  },
  {
    path: "/recommendation/:recommendationId",
    component: RecommendationDetailsPage,
    featureKey: null,
  },
  {
    path: "/noniprecommendations",
    component: NonIPRecommendationsList,
    featureKey: "noniprecommendations",
  },
  {
    path: "/noniprecommendation/:recommendationId",
    component: NonIPRecommendations,
    featureKey: null,
  },
  {
    path: "/op/comprehensive",
    component: OpComprehensive,
    featureKey: "opd_analysis",
  },
  {
    path: "/op/ip_potential",
    component: HighIpPotential,
    featureKey: "opd_analysis",
  },
];
